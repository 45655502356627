// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// Define custom palette using http://mcg.mbitson.com
$custom-primary-palette: (
    50 : #e0f4f3,
    100 : #b3e3e1,
    200 : #80d0cd,
    300 : #4dbdb9,
    400 : #26afaa,
    500 : #00a19b,
    600 : #009993,
    700 : #008f89,
    800 : #00857f,
    900 : #00746d,
    A100 : #a2fff9,
    A200 : #6ffff5,
    A400 : #3cfff2,
    A700 : #23fff0,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$custom-accent-palette: (
    50 : #ffffff,
    100 : #fde4d7,
    200 : #f9c0a2,
    300 : #f5935f,
    400 : #f47f42,
    500 : #f26c25,
    600 : #ea5a0e,
    700 : #ce4f0c,
    800 : #b1440b,
    900 : #943909,
    A100 : #ffffff,
    A200 : #ffccb1,
    A400 : #ff894b,
    A700 : #f97b38,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$web-app-primary: mat.define-palette($custom-primary-palette, 500);
$web-app-accent: mat.define-palette($custom-accent-palette, 500);
// $web-app-primary: mat-palette($mat-indigo);
// $web-app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$web-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$web-app-theme: mat.define-light-theme((
  color: (
    primary: $web-app-primary,
    accent: $web-app-accent,
    warn: $web-app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($web-app-theme);

/* You can add global styles to this file, and also import other style files */


/* Import bootstrap component */
// @import "../node_modules/bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/bootstrap-grid";
// @import "../node_modules/bootstrap";

@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700");


html, body { height: 100%; }
body { 
  margin: 0; 
  font-family: Roboto, "Helvetica Neue", sans-serif; 
  background-color: #EDF3F8;
  font-size:14px;

}
